import React, { FC, useState } from "react";
import { Toast, ActionSheet, Popup, Dialog } from "antd-mobile-v5";
import {
  geConfigRecharge,
  getPayType,
  getRechargeEmpList,
  userRecharge,
  getUserLevelCard,
} from "@api/user";
import { Button } from "antd-mobile-v5";
import PayUtils from "@utils/pay_utils";
import { useMount } from "ahooks";
import { failOrderCallBack } from "@api/order";
import { getStorage } from "@utils/my_storage";
import { getAppPageConfig } from "@api/app";
import { apiUrl } from "@utils/http";
import Tools from "@utils/tools";
import { useWxPay } from "@utils/app_hooks";
import "./index.scss";

export const webUrl = process.env.REACT_APP_Host_URL;

const RechargePageItem: FC = () => {
  const userWxPay = useWxPay();
  const urlParam = Tools.param2Obj();

  const [now_idx, setNowIdx] = useState(-1);
  const [payStatus, setPayStatus] = useState(false);
  const [pay_arr, setPay_arr] = useState([]);
  const [card_type_idx, set_card_type_idx] = useState(0);
  const [card_arr, set_card_arr] = useState<any>([]);
  const [rechargespan, set_rechargespan] = useState("");
  const [nowRechargeType, set_nowRechargeType] = useState({
    type_card_id: 0,
    id: 0,
  });
  const [user_info, set_user_info] = useState({
    username: "",
    header_img: "",
    wallet: {
      balance: "",
    },
  });
  const [cards_info, set_cards_info] = useState({
    id: "",
    rule: [],
  });
  const [emp_info, set_emp_info] = useState({
    id: -1,
    name: "",
  });
  const [rechargeItem, setRechargeItem] = useState<any>({
    charge_money: "0",
    coupon_data: [],
    coupon_ids: "",
    coupon_status: "0",
    first_discount: "0",
    first_pay_discount: "0",
    good_name: "",
    good_status: "0",
    is_lock: "0",
    is_show: 1,
    is_unify: "0",
    liter_good_money: [],
    lock_good_money: [],
    money: "10",
    money_status: "1",
    point: "0",
    point_status: "0",
  });
  const [empStatus, set_empStatus] = useState(false);
  const [empArr, set_empArr] = useState([{ id: 0, name: "暂不选择" }]);

  useMount(() => {
    getCardsArr();
    getPayInfo();
    getEmpUserList();
    isUsedPoint();
  });

  const isUsedPoint = async () => {
    try {
      const res = await getAppPageConfig("recharge_notice");
      if (res.code === 200) {
        if (res.data.status === 1) set_rechargespan(res.data.value);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setNowItems = (idx: number, item: any) => {
    setNowIdx(idx);
    console.log(item);
    setRechargeItem(item);
  };

  const getCardsItems = async (id: number, isVip: number) => {
    try {
      const res = await geConfigRecharge(id, isVip);
      set_cards_info(res.data);
      setRechargeItem({});
      setNowIdx(-1);
    } catch (error) {
      console.log(error);
    }
  };

  const getPayInfo = async () => {
    try {
      const env = Tools.getSystemPayEnv();
      const res = await getPayType({ source: env });
      if (res.code === 200) {
        const payArr = ["wxpay", "fuiou", "normal_allinpay"];
        res.data.forEach(
          (item: { text: any; name: any; key: any; payment_code: any }) => {
            if (payArr.indexOf(item.payment_code) > -1) {
              if (env === "wx") {
                item.text = "微信支付";
              } else {
                item.text = "支付宝支付";
              }
            } else item.text = item.name;
            item.key = item.payment_code;
          }
        );
        setPay_arr(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const cardItems = () => {
    if (!cards_info.hasOwnProperty("rule")) return <></>;
    return cards_info.rule.map((item: any, index: number) => {
      return (
        <div
          key={index}
          className={now_idx === index ? "active" : "save_money"}
          onClick={() => setNowItems(index, item)}
        >
          <div className="save_tag">有礼</div>
          <span
            className={
              now_idx === index
                ? "charge_money_title_active"
                : "charge_money_title"
            }
          >
            {item.charge_money}
          </span>
          {item.money > 0 && (
            <span className="charge_money_dis"> 赠{item.money}元 </span>
          )}
        </div>
      );
    });
  };

  const getCardsArr = async () => {
    try {
      Toast.show("请稍候...");
      const res = await getUserLevelCard();
      if (res.code === 200) {
        const { user_info, card_info, card } = res.data;
        let arr = [
          {
            type_card_id: card?.id,
            id: 0,
            name: "会员卡",
            balance: user_info.wallet.wallet_balance,
            isVip: 0,
          },
        ];
        set_card_arr(arr.concat(card_info));
        set_user_info(res.data.user_info);
        getCardsItems(card?.id, 0);
        Toast.clear();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const payOrderItem = async (item: any) => {
    try {
      const env = Tools.getSystemPayEnv();
      if (emp_info.name === "") return set_empStatus(true);
      Toast.show("请稍候...");
      // const now_pay = ["wxpay", "fuiou", "unionpay", "normal_allinpay"];
      const data = {
        account_id: nowRechargeType.id,
        key: now_idx,
        payment_code: item.payment_code,
        recharge_id: cards_info.id,
        source: env,
        type: nowRechargeType.id === 0 ? 0 : 1,
        qrcode_id: urlParam.qrcode_id,
        emp_id: emp_info.id,
        room_id: urlParam.room_id,
      };
      const res = await userRecharge(data);
      if (res.code === 200) {
        setPayStatus(false)
        if (item.payment_code === "cash") {
          Dialog.alert({
            title: "温馨提示",
            content: "请与加油员工确认后离开",
          });
        } else {
          if (env === "ali") {
            const status = await PayUtils.aliPay(res.data.targetOrderId);
            if (!status) await failOrderCallBack(res.data.ordersn);
            if (status) {
              Toast.show("支付成功!");
            } else {
              Toast.show("支付失败!");
            }
          } else if (env === "h5") {
            window.location.href = res.data.mweb_url;
          } else {
            const callbackUrl =
              `${webUrl}/time_pay?app_id=${getStorage("app_id")}` +
              `&app_token=${getStorage("app_token")}&paysn=${
                res.data.paysn
              }&ordersn=${res.data.ordersn}`;
            const url = `${apiUrl}/client/wechat.pay_login/snsapi_base?app_id=${getStorage("app_id")}&callback=${encodeURIComponent(
              callbackUrl
            )}`;
            if (item.payment_code === "fuiou") {
              if (res.data.pay_page === 1) {
                window.location.href = url;
              } else {
                userWxPay.pay(res.data);
              }
            } else if (item.payment_code === "normal_allinpay") {
              if (res.data.pay_page === 1) {
                window.location.href = url;
              } else {
                userWxPay.pay(res.data);
              }
            } else if (item.payment_code === "lakala") {
              if (res.data.pay_page === 1) {
                window.location.href = url;
              } else {
                userWxPay.pay(res.data);
              }
            } else if (item.payment_code === "leshua") {
              if (res.data.pay_page === 1) {
                window.location.href = url;
              } else {
                userWxPay.pay(res.data);
              }
            } 
            else if (item.payment_code === "unionpay") {
              window.location.href = res.data.url;
            } else {
              userWxPay.pay(res.data);
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  // 充值每升减优惠展示
  const rechargeUnify = () => {
    if (rechargeItem.is_unify === "1") {
      return rechargeItem.liter_good_money.map((item: any) => {
        return item.itemArr.map(
          (child: any, idx: number) =>
            child.money && (
              <div key={idx}>
                {child.name}每升减{child.money}元
              </div>
            )
        );
      });
    } else return <></>;
  };

  // 充值锁油价优惠展示
  const rechargeLock = () => {
    if (rechargeItem.is_lock === "1") {
      return rechargeItem.lock_good_money.map((item: any) => {
        return item.itemArr.map(
          (child: any, idx: number) =>
            child.money && (
              <div key={idx}>
                {child.name}锁油价{child.money}元
              </div>
            )
        );
      });
    } else return <></>;
  };

  const setNowRecharge = (item: any, index: number) => {
    set_card_type_idx(index);
    set_nowRechargeType(item);
    getCardsItems(item.type_card_id, item.id === 0 ? 0 : 1);
  };

  const rechargeType = (item: any, index: number) => {
    return (
      <div
        key={index}
        onClick={() => setNowRecharge(item, index)}
        className={
          card_type_idx === index
            ? "recharge-list-item item-active"
            : "recharge-list-item"
        }
      >
        <span>{item.name}</span>
        <span>剩余: {item.balance}元</span>
      </div>
    );
  };

  const payRecharge = (item: any) => {
    payOrderItem(item);
  };

  const rechargePayItem = () => {
    if (now_idx === -1) return Toast.show("请选择充值项");
    setPayStatus(true);
  };

  // 获取员工列表
  const getEmpUserList = async () => {
    try {
      let qrcode_id = Number(getStorage("qrcode_id"));
      let sn = Number(getStorage("sn"));
      let param: any = { qrcode_id, room_id: urlParam.room_id, sn };
      for (const key in param) {
        if (Object.prototype.hasOwnProperty.call(param, key)) {
          const element = param[key];
          if (!element) delete param[key];
        }
      }
      const res = await getRechargeEmpList(param);
      if (res.code === 200) {
        if (res.data.length === 1) {
          set_emp_info(res.data[0]);
          set_empArr(res.data);
        } else {
          set_empArr([...res.data, ...empArr]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeEmpType = (item: any) => {
    set_empStatus(false);
    set_emp_info(item);
  };

  // 员工姓名展示
  const empName = () => {
    const { id, name } = emp_info;
    if (Number(id) > -1)
      return <span onClick={() => set_empStatus(true)}>员工: {name}</span>;
    return <span onClick={() => set_empStatus(true)}>选择员工</span>;
  };

  // 员工列表
  const empListItem = (item: any, index: number) => {
    const { id } = emp_info;
    return (
      <Button
        key={index}
        onClick={() => changeEmpType(item)}
        className={
          item.id === id ? "oil_list_item oil_list_item_hover" : "oil_list_item"
        }
      >
        <span className="oil_span">{item.name}</span>
        {/* <span className='oil_span'>{item.name}</span> */}
      </Button>
    );
  };

  const payInfo = () => {
    return (
      <ActionSheet
        extra="请选择支付方式"
        cancelText="取消"
        visible={payStatus}
        actions={pay_arr}
        onAction={(val) => payRecharge(val)}
        onClose={() => setPayStatus(false)}
      />
    );
  };

  // 充值项赠送信息
  const rechargeItemInfo = () => {
    if (now_idx === -1) return <></>;
    return (
      <div className="recharge-info">
        {rechargeItem.money_status === "1" && rechargeItem.money !== "" && (
          <div>赠送 {rechargeItem.money} 元 余额</div>
        )}
        {rechargeItem.point_status === "1" && (
          <div>赠送 {rechargeItem.point} 积分</div>
        )}
        {rechargeItem.coupon_status === "1" && (
          <div>赠送 {rechargeItem.coupon_data.length} 张 优惠券</div>
        )}
        {rechargeItem.good_name && <div>赠送 {rechargeItem.good_name} </div>}
        {rechargeUnify()}
        {rechargeLock()}
        {rechargeItem.first_discount === "1" && (
          <div>
            {rechargeItem.first_pay_discount === "100"
              ? "首单享受免单优惠"
              : "首单享受" + (10-Number(rechargeItem.first_pay_discount) / 10) + "折"}
          </div>
        )}
      </div>
    );
  };

  // 充值信息提醒
  const rechargeInfospan = () => {
    return (
      <div className="recharge-span">
        <span>{rechargespan}</span>
      </div>
    );
  };

  return (
    <div className="card_con">
      <div className="card_banner">
        <div className="card_banner_top">
          <div className="card_banner_top_img">
            <img className="top_img" src={user_info?.header_img} alt="" />
          </div>
          <div className="card_banner_info">
            <div>手机号: {user_info.username}</div>
            <div>余额: {user_info.wallet.balance}</div>
          </div>
        </div>
      </div>
      <div className="card-type">
        <div className="card-title">
          <span>储值类型</span>
        </div>
        <div className="recharge-list">
          {card_arr.length > 0 &&
            card_arr.map((item: any, index: number) =>
              rechargeType(item, index)
            )}
        </div>
      </div>
      <div className="card_list">
        <div className="card_title">
          <span>请选择充值金额</span>
          {empName()}
        </div>
        <div className="recharge-con">{cardItems()}</div>
      </div>
      {rechargeItemInfo()}
      {rechargeInfospan()}
      {payInfo()}
      <div className="bottom_btn">
        <Button
          type="button"
          className="bottom_btn_sty"
          color="primary"
          block
          size="large"
          onClick={() => rechargePayItem()}
        >
          充值
        </Button>
      </div>
      <Popup
        visible={Boolean(empStatus)}
        onMaskClick={() => set_empStatus(false)}
      >
        <div className="emp_list">
          {empArr.map((item, index) => empListItem(item, index))}
        </div>
      </Popup>
    </div>
  );
};

export default RechargePageItem;
